'use client';

import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useStringWithGlobals,
  useTranslationContext,
} from 'src/components/contexts/TranslationContext';
import { markdownToHtmlString } from 'src/tapestry/core/markdown';
import { SmartLink } from 'src/tapestry/core/links/smart-link';

const faqRenderer = {
  link: ({ href, children }) => (
    <SmartLink href={href} rel="noopener noreferrer">
      {children}
    </SmartLink>
  ),
};

export const FAQSchema = ({ faqs }) => {
  const { locale } = useTranslationContext();
  const makeFaqList = faqs.map(({ answer, question }) => {
    const answerHtml = markdownToHtmlString({
      source: answer?.answer ?? answer,
      renderers: faqRenderer,
      locale,
    });

    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answerHtml,
      },
    };
  });

  const ldJsonString = useStringWithGlobals(
    JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: makeFaqList,
    })
  );

  return (
    <Helmet>
      <script type="application/ld+json">{ldJsonString}</script>
    </Helmet>
  );
};

export const generateBreadCrumbs = crumbs => {
  if (!crumbs.length) return null;
  const itemListElement = crumbs.map((crumb, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      ...crumb,
    };
  });

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  });
};

export const tableMarkupRenderer = {
  table: ({ children }) => (
    <table itemScope itemType="https://schema.org/Table">
      {children}
    </table>
  ),
  tableCell: ({ children, isHeader }) => {
    return isHeader ? <th scope="col">{children}</th> : <td>{children}</td>;
  },
};

export const TableSchema = ({ title }) => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Table',
    about: title,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const ExchangeRateSchema = ({ name, symbol, price, url }) => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ExchangeRateSpecification',
    url,
    name,
    currency: symbol,
    currentExchangeRate: {
      '@type': 'UnitPriceSpecification',
      price,
      priceCurrency: 'CAD',
    },
  };

  // See the Next.js docs: https://nextjs.org/docs/app/building-your-application/optimizing/metadata#json-ld
  // eslint-disable-next-line react/no-danger
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />;
};

export const BreadcrumbsSchema = ({ breadcrumbs }) => (
  // See the Next.js docs: https://nextjs.org/docs/app/building-your-application/optimizing/metadata#json-ld
  // eslint-disable-next-line react/no-danger
  <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: generateBreadCrumbs(breadcrumbs) }} />
);
