const capitalizeFirstLetter = string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

const kebabToCamel = string => {
  return string.replace(/-./g, match => match[1].toUpperCase());
};

const spacesToCamel = string => {
  if (!string) {
    return '';
  }

  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => {
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    })
    .replace(/\W/g, '')
    .replace(/\s+/g, '');
};

/*
 * eg: kebabToPascal('example-string') === 'ExampleString'
 *
 * @param {string}
 * @returns {string} A Pascal-cased string
 */
const kebabToPascal = string => {
  let newString = kebabToCamel(string);
  newString = newString.charAt(0).toUpperCase() + newString.substring(1);
  return newString;
};

/*
 * Given a string, removes any pascal casing and replaces it with spaces
 *  and lower case.
 *
 * Used to convert component names to format desired by data team
 *
 * eg: pascalCaseToSpacedLowerCase('CoolComponent') === 'cool component'
 *
 * @param {string}
 * @returns {string} A lower-cased string with spaces
 */
const pascalCaseToSpacedLowerCase = string => {
  if (typeof string !== 'string') return '';
  return string
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/^ /, '')
    .toLowerCase();
};

module.exports = {
  capitalizeFirstLetter,
  kebabToCamel,
  kebabToPascal,
  pascalCaseToSpacedLowerCase,
  spacesToCamel,
};
