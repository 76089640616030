'use client';

import React, { useContext } from 'react';

const ComponentContext = React.createContext({});
const { Provider: ComponentContextProvider, Consumer: ComponentContextConsumer } = ComponentContext;
ComponentContext.displayName = 'ComponentContext';

const useComponentContext = () => useContext(ComponentContext);

export {
  ComponentContextConsumer,
  ComponentContextProvider,
  ComponentContext,
  useComponentContext,
};
