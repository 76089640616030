import React from 'react';

/**
 * A shim component to work around Next.js and Gatsby's different webpack
 * configurations. Next imports static files as ES modules; Gatsby does not.
 */
export const Image = ({ alt = '', src, ...rest }) => {
  const _src = src?.src ?? src;
  return <img src={_src} alt={alt} {...rest} />;
};
