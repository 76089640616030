'use client';

import React, { useState, useLayoutEffect, useCallback } from 'react';
import { replaceHashWithoutScroll } from 'src/utils/window';

// so that scrollTo position isn't covered by header
const MENU_OFFSET = 100;

export const AnchorLink = ({
  children,
  href,
  className = '',
  activeClass = '',
  onClick = () => null,
  scrollYOffset = MENU_OFFSET,
  shouldFocus = false,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);

  // Active, as in has same href hash as in page url
  const setActiveHash = () => {
    setIsActive(window.location.hash === href);
  };

  const clickOverride = e => {
    // hash navigation handled manually
    e.preventDefault();

    // update url with the target url hash
    replaceHashWithoutScroll(href);

    // scroll to target element
    scrollToHref();

    // trigger external event listener
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  const scrollToHref = useCallback(() => {
    // target element ID and url hash are assumed to be the same
    const anchorElement = document.getElementById(href.replace('#', ''));

    if (anchorElement) {
      const targetScrollY =
        anchorElement.getBoundingClientRect().top + window.pageYOffset - scrollYOffset;
      window.scroll({
        top: targetScrollY,
        behavior: 'smooth',
      });

      if (shouldFocus) {
        anchorElement.focus();
      }
    }
  }, [href, scrollYOffset, shouldFocus]);

  // The <a> element doesn't need the offset attribute
  delete rest.offset;

  useLayoutEffect(() => {
    // checks if url hash is the same as instance hash
    setActiveHash();

    // Get notified when the hash changes so we can update link styling
    window.addEventListener('hashchange', setActiveHash);
    window.addEventListener('popstate', setActiveHash);
    window.addEventListener('pushstate', setActiveHash);

    return () => {
      window.removeEventListener('hashchange', setActiveHash);
      window.removeEventListener('popstate', setActiveHash);
      window.removeEventListener('pushstate', setActiveHash);
    };
  }, []);

  return (
    // We're intercepting default <a> behavior, but screen readers should
    // have no reason to differentiate this and a normal <a> elememt.
    <a
      {...rest}
      href={href}
      className={isActive ? `${className} ${activeClass}` : className}
      onClick={clickOverride}
    >
      {children}
    </a>
  );
};
