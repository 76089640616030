import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { Markdown } from 'src/tapestry/core/markdown';
import { parseScssMapToJs, classNames, generateFluidClampString } from 'src/utils/css';
import * as scssVars from 'src/styles/typographyMaps.module.scss';

export const CLASS_TEXT_WRAP_BALANCED = 'ws-balance';
export const CLASS_TEXT_UPPERCASE = 'ws-uppercase';

export const DEFAULT_CONFIG_BY_ELEMENT_TYPE = (() => {
  const elements = {
    h1: { min: 'ws-display-lg-serif', max: 'ws-display-2xl-serif' },
    h2: { min: 'ws-display-md-serif', max: 'ws-display-xl-serif' },
    h3: { min: 'ws-display-sm-sans', max: 'ws-display-md-sans' },
    p: { min: 'ws-text-lg', max: 'ws-text-2xl' },
  };
  elements.div = elements.p;
  elements.span = elements.p;
  elements.markdown = elements.p;
  return elements;
})();

const DEFAULT_BALANCED_ELEMENT_TYPES = ['h1', 'h2'];

const DEFAULT_MIN_BREAKPOINT = 376;
const DEFAULT_MAX_BREAKPOINT = 1440;
const SUPPORTED_CLASS_PREFIX = 'ws-';
const PREFIX_LENGTH = SUPPORTED_CLASS_PREFIX.length;
const DEFAULT_TEXT_WEIGHT = 'book';
const DEFAULT_DISPLAY_WEIGHT = 'serif';
const GUTTER_SIZE = 32;
// So that text scale and grid scale are aligned
const DEFAULT_SCALE_OFFSET = GUTTER_SIZE * 2;
const TEXT_SIZES_MAP = parseScssMapToJs(scssVars.textSizes);
const DISPLAY_SIZES_MAP = parseScssMapToJs(scssVars.displaySizes);
const EYEBROW_SIZES_MAP = parseScssMapToJs(scssVars.eyebrowSizes);
const FONT_WEIGHT_MAP = parseScssMapToJs(scssVars.fontWeights);
const LETTER_SPACING_MAP = parseScssMapToJs(scssVars.letterSpacings);
const LINE_HEIGHTS_DISPLAY_MAP = parseScssMapToJs(scssVars.lineHeightsDisplay);
const LINE_HEIGHTS_TEXT_AND_EYEBROW_MAP = parseScssMapToJs(scssVars.lineHeightsTextAndEyebrow);
const FONT_FAMILY_MAP = parseScssMapToJs(scssVars.fontFamilies);
const TEXT_TRANSFORMS_MAP = parseScssMapToJs(scssVars.textTransforms);

const parseClassName = className => {
  const classNameParts = className.split('-');
  const fontTypeKey = classNameParts[1];
  const fontSizeKey = classNameParts[2];
  let fontWeight = classNameParts[3];

  // apply default variants to display and text
  if (!classNameParts[3]) {
    switch (fontTypeKey) {
      case 'display':
        fontWeight = DEFAULT_DISPLAY_WEIGHT;
        break;
      case 'text':
        fontWeight = DEFAULT_TEXT_WEIGHT;
        break;
      default:
    }
  }

  return {
    fontTypeKey,
    fontSizeKey,
    fontWeightKey: fontWeight,
  };
};

const getTextTransforms = (type, variant) => {
  switch (type) {
    case 'display':
      return TEXT_TRANSFORMS_MAP[variant];
    case 'eyebrow':
      return TEXT_TRANSFORMS_MAP[type];
    default:
      return null;
  }
};

const getFontSize = (type, size) => {
  switch (type) {
    case 'text':
      return TEXT_SIZES_MAP[size];
    case 'display':
      return DISPLAY_SIZES_MAP[size];
    case 'eyebrow':
      return EYEBROW_SIZES_MAP[size];
    default:
      return null;
  }
};

const getLetterSpacing = (type, variant) => {
  switch (type) {
    case 'text':
    case 'display':
      return LETTER_SPACING_MAP[variant];
    case 'eyebrow':
      return LETTER_SPACING_MAP[type];
    default:
      return null;
  }
};

const getLineHeight = (type, size) => {
  switch (type) {
    case 'display':
      return LINE_HEIGHTS_DISPLAY_MAP[size];
    case 'text':
    case 'eyebrow':
      return LINE_HEIGHTS_TEXT_AND_EYEBROW_MAP[size];
    default:
      return null;
  }
};

const getFontFamily = (type, variant) => {
  switch (type) {
    case 'text':
    case 'display':
      return FONT_FAMILY_MAP[variant];
    case 'eyebrow':
      return FONT_FAMILY_MAP[type];
    default:
      return null;
  }
};

const getFontWeight = (type, variant) => {
  switch (type) {
    case 'text':
    case 'display':
      return FONT_WEIGHT_MAP[variant];
    case 'eyebrow':
      return FONT_WEIGHT_MAP[type];
    default:
      return null;
  }
};

const translateScss = config => {
  const { fontTypeKey, fontSizeKey, fontWeightKey } = config;
  return {
    fontSize: getFontSize(fontTypeKey, fontSizeKey),
    fontWeight: getFontWeight(fontTypeKey, fontWeightKey),
    letterSpacing: getLetterSpacing(fontTypeKey, fontWeightKey),
    fontFamily: getFontFamily(fontTypeKey, fontWeightKey),
    lineHeight: getLineHeight(fontTypeKey, fontSizeKey),
    textTransform: getTextTransforms(fontTypeKey, fontWeightKey),
  };
};

const classNameIsValid = wsClass => {
  return isString(wsClass) && wsClass.slice(0, PREFIX_LENGTH) === SUPPORTED_CLASS_PREFIX;
};

const processMinMax = (type, min, max) => {
  if (DEFAULT_CONFIG_BY_ELEMENT_TYPE[type]) {
    // Fill in min and max from type defaults when one or both values is not given
    return [
      min || DEFAULT_CONFIG_BY_ELEMENT_TYPE[type].min,
      max || DEFAULT_CONFIG_BY_ELEMENT_TYPE[type].max,
    ];
  }

  if (!classNameIsValid(min) || !classNameIsValid(max)) {
    throw new Error(
      `min & max props must be assigned a _typography.scss class - any display or text classes that begins with "ws-")`
    );
  }

  return [min, max];
};

const processAll = all => {
  if (!classNameIsValid(all)) return false;
  const classConfig = parseClassName(all);
  const translated = translateScss(classConfig);
  return translated;
};

export const FluidText = forwardRef(function FluidText(
  {
    children,
    className,
    type = 'span',
    all,
    min,
    max,
    minPx = 18,
    maxPx = 24,
    minBreakpoint = DEFAULT_MIN_BREAKPOINT,
    maxBreakpoint = DEFAULT_MAX_BREAKPOINT,
    scaleOffset = DEFAULT_SCALE_OFFSET,
    uppercase,
    style = {},
    isBalanced,
    markdownProps,
    ...rest
  },
  ref
) {
  const isMarkdown = type === 'markdown';
  const TextWrapper = isMarkdown ? 'div' : type;
  const [_min, _max] = processMinMax(type, min, max);
  const classConfigMin = parseClassName(_min ?? minPx);
  const classConfigMax = parseClassName(_max ?? maxPx);
  const translatedStylesMin = translateScss(classConfigMin);
  const translatedStylesMax = translateScss(classConfigMax);
  const scaleOffsetParsed = parseInt(scaleOffset, 10);
  const clampString = generateFluidClampString(
    parseInt(translatedStylesMin.fontSize, 10),
    parseInt(translatedStylesMax.fontSize, 10),
    minBreakpoint - scaleOffsetParsed,
    maxBreakpoint - scaleOffsetParsed
  );
  const shouldBalanceText = isBalanced ?? DEFAULT_BALANCED_ELEMENT_TYPES.includes(type);
  const stylesMerged = {
    ...style,
    ...translatedStylesMin,
    fontSize: clampString,
    ...{ ...(processAll(all) ?? {}) },
  };

  return (
    <TextWrapper
      ref={ref}
      className={classNames(className, uppercase && CLASS_TEXT_UPPERCASE, shouldBalanceText && CLASS_TEXT_WRAP_BALANCED)}
      style={stylesMerged}
      {...rest}
    >
      {isMarkdown ? <Markdown source={children} {...markdownProps} /> : children}
    </TextWrapper>
  );
});

FluidText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  all: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minBreakpoint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxBreakpoint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scaleOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape(),
  uppercase: PropTypes.bool,
  // deprecated - dont use
  minPx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxPx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBalanced: PropTypes.bool,
};
