'use client';

import { useState, useLayoutEffect, useEffect } from 'react';
import { getAnonymousId } from 'src/services/analytics-service';
import { stubAnalyticsMethod } from 'lib/segment';

export const useForwardQueryParams = () => {
  const searchParamsObject = new URLSearchParams('');
  const [searchParams, setSearchParams] = useState(searchParamsObject.toString());

  const updateSearchParams = () => {
    setSearchParams(searchParamsObject.toString());
  };

  useEffect(() => {
    const pageParams = window.location.search;
    const pageParamsObject = new URLSearchParams(pageParams);
    pageParamsObject.forEach((value, key) => {
      searchParamsObject.set(key, value);
    });
    updateSearchParams();
  }, []);

  /**
   * Attempt to get the user's anonymousId from Segment
   * */
  const appendUidToCtaUrl = () => {
    window.analytics.ready(() => {
      const anonymousId = getAnonymousId();

      if (anonymousId) {
        searchParamsObject.set('anon_id', anonymousId);
        updateSearchParams();
      }
    });
  };

  useLayoutEffect(() => {
    if (!window?.analytics?.ready) {
      stubAnalyticsMethod('ready');
    }

    appendUidToCtaUrl();
  }, []);

  return searchParams;
};
