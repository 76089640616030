'use client';

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import { TranslationsProvider } from 'src/components/contexts/TranslationContext';
import { FluidText } from 'src/components/common/FluidText';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { list_, checklist_ } from './markdown.module.scss';

export const commonRenderers = {
  link: {
    nofollow: {
      link: ({ children, href }) => (
        <SmartLink href={href} rel="nofollow">
          {children}
        </SmartLink>
      ),
    },
  },
  heading: ({ children }) => (
    <FluidText
      type="h3"
      min="ws-text-lg-medium"
      max="ws-text-2xl-medium"
    >
      {children}
    </FluidText>),
  list: ({ children, ordered }) => {
    const ListElement = ordered ? 'ol' : 'ul';
    return (
      <ListElement className={list_}>
        {children}
      </ListElement>
    );
  }
};

export const MARKDOWN_MODES = {
  inlineText: 'inlineText',
  textWithLists: 'textWithLists',
  textWithHeadingsAndLists: 'textWithHeadingsAndLists',
  checklist: 'checklist',
};

export const DEFAULT_RENDERERS = {
  link: ({ children, ...rest }) => <SmartLink {...rest}>{children}</SmartLink>,
  // Info on sub and sup here
  // https://github.com/zestedesavoir/zmarkdown/blob/7194140ad770fe4ac1078b0574c91d1b97e8463e/packages/remark-sub-super/src/index.js#L2-L4
  sub: ({ children }) => <sub>{children}</sub>,
  sup: ({ children }) => <sup>{children}</sup>,
  strong: ({ children }) => <strong className="ws-medium">{children}</strong>,
};

const SHARED_DISALLOWED_TYPES = [
  // https://github.com/remarkjs/react-markdown/tree/v4.3.1#node-types
  // This ^ at this point in time is no longer reflected in the readme even though it's the version we're on (?!)
  // ...so here's the list in the code: https://github.com/remarkjs/react-markdown/blob/v4.3.1/src/renderers.js#L10-L39
  'break',
  'thematicBreak',
  'blockquote',
  'delete',
  'image',
  'imageReference',
  'table',
  'tableHead',
  'tableBody',
  'tableRow',
  'tableCell',
  'definition',
  'inlineCode',
  'code',
  'html',
];

const LIST_TYPES = ['list', 'listItem'];
const HEADING_TYPE = 'heading';

const INLINE_TEXT_MODE_DISALLOWED_TYPES = [
  ...SHARED_DISALLOWED_TYPES,
  ...LIST_TYPES,
  HEADING_TYPE,
];

const TEXT_WITH_LISTS_MODE_DISALLOWED_TYPES = [
  ...SHARED_DISALLOWED_TYPES,
  HEADING_TYPE,
];

const CHECKLIST_DISALLOWED_TYPES = [
  ...SHARED_DISALLOWED_TYPES,
  HEADING_TYPE,
];

const TEXT_WITH_HEADINGS_AND_LISTS_MODE_DISALLOWED_TYPES = [
  ...SHARED_DISALLOWED_TYPES,
];

const TEXT_WITH_LISTS_MODE_RENDERERS = {
  list: commonRenderers.list,
};

const TEXT_WITH_HEADINGS_AND_LISTS_MODE_RENDERERS = {
  heading: commonRenderers.heading,
  list: commonRenderers.list,
};

const CHECKLIST_MODE_RENDERERS = {
  list: ({ children, ordered }) => {
    const ListElement = ordered ? 'ol' : 'ul';
    return (
      <ListElement className={checklist_}>
        {children}
      </ListElement>
    );
  }
};

export const MODE_CONFIGS = {
  [MARKDOWN_MODES.inlineText]: {
    disallowedTypes: INLINE_TEXT_MODE_DISALLOWED_TYPES,
  },
  [MARKDOWN_MODES.textWithLists]: {
    renderers: TEXT_WITH_LISTS_MODE_RENDERERS,
    disallowedTypes: TEXT_WITH_LISTS_MODE_DISALLOWED_TYPES,
  },
  [MARKDOWN_MODES.textWithHeadingsAndLists]: {
    renderers: TEXT_WITH_HEADINGS_AND_LISTS_MODE_RENDERERS,
    disallowedTypes: TEXT_WITH_HEADINGS_AND_LISTS_MODE_DISALLOWED_TYPES,
  },
  [MARKDOWN_MODES.checklist]: {
    renderers: CHECKLIST_MODE_RENDERERS,
    disallowedTypes: CHECKLIST_DISALLOWED_TYPES,
  },
};

export const markdownToHtmlString = ({ source, renderers, locale }) => {
  if (!locale) {
    throw new Error(
      'You must provide a locale prop since this function is not able to infer the current locale on its own.'
    );
  }

  return renderToStaticMarkup(
    // This is a new React root, so we don't have our app-level contexts here.
    // Need to pass in a bare minimum so that our components in `renderers`
    // have the contexts they expect.
    <TranslationsProvider locale={locale}>
      <ReactMarkdown source={source} renderers={renderers} />
    </TranslationsProvider>
  );
};
