export const EVENTS = {
  FINISH_SIGNUP: 'Finish Signup',
  CLICK_CTA: 'Click WWW CTA',
  MAGAZINE_ARTICLE_CLICKED: 'Magazine Article Clicked',
  MAGAZINE_EMAIL_CAPTURED: 'Magazine Email Captured',
  CONTENT_HUB_EMAIL_CAPTURED: 'Content Hub Email Captured',
  SOCIAL_SHARE_CLICKED: 'Social Share Clicked',
  LEARN_ARTICLE_CTA_CLICKED: 'Learn Article CTA Clicked',
  RETIREMENT_CALCULATOR_VIEWED: 'Retirement Calculator Schedule A Call CTA Viewed',
  RETIREMENT_CALCULATOR_SCHEDULE_A_CALL_CTA_CLICKED:
    'Retirement Calculator Schedule A Call CTA Clicked',
  RETIREMENT_CALCULATOR_SCHEDULE_A_CALL_CLOSE_CLICKED:
    'Retirement Calculator Schedule A Call Close Clicked',
  VIDEO_CONTROLS_CLICKED: 'Video controls Clicked',
};
