'use client';

import React, { forwardRef } from 'react';
// import Link from 'next/link';
import { pathnameHasLocale } from 'src/utils/urls';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';

export const LocalizedLink = forwardRef(function LocalizedLink({ to, children, ...props }, ref) {
  const { locale } = useTranslationContext();
  const path = pathnameHasLocale(to) ? to : `/${locale}${to}`;

  // TODO: once migration is complete (or partway through it), use next/link.
  //
  // Currently we cannot use next/link as it tries to client-side link to Gatsby
  // pages and results in a 404. There is a workaround for this, but it means
  // losing the ability to localize the 404 page for `/fr-ca/*`.
  //
  // Once we reach a critical mass of pages rendering in NextJS, we may want to
  // flip this so that _Gatsby_ renders an `<a>`, shifting the performance
  // tradeoff to the smaller subset of paths.
  //
  // return (
  //   <Link
  //   {...props}
  //   href={path}
  //   ref={ref}
  //   >
  //     {children}
  //   </Link>
  // );

  return <a {...props} href={path} ref={ref}>{children}</a>;
});
