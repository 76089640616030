'use client';

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  getLoginUrl,
  getLegacySignupUrl,
  getPathFromLocalUrl,
  WEB_PRODUCT_URL,
  LEGACY_SIGNUP_URL,
  LOCALIZED_LOGIN_URL,
  replaceLocaleInPathname,
  pathnameHasLocale,
  MORTGAGES_PINE_URL,
} from 'src/utils/urls';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { trackSmartLinkClick } from './smart-link.helpers';
import { LocalizedLink } from '../localized-link';
import { AnchorLink } from '../anchor-link';
import { inlineBlock_ } from './smart-link.module.scss';
import { ExternalForwardQueryParamsLink } from '../ExternalForwardQueryParamsLink';

const EXTERNAL_LINK_TYPES = ['a', ExternalForwardQueryParamsLink];

const getLinkTag = ({ href }) => {
  if (href) {
    if (href.startsWith('#')) return AnchorLink;
    if (href.startsWith('/') && !href.startsWith('//')) return LocalizedLink;
    if (href.startsWith(MORTGAGES_PINE_URL)) return ExternalForwardQueryParamsLink;
  }
  return 'a';
};

const isExternalLink = linkTag => {
  return EXTERNAL_LINK_TYPES.includes(linkTag);
};

const getRel = (linkTag, nofollow) => {
  let rel = '';
  if (isExternalLink(linkTag)) {
    rel += 'noopener noreferrer';
    if (nofollow) {
      rel += ' nofollow';
    }
  }
  return rel;
};

export const SmartLink = forwardRef(function SmartLink(
  {
    href,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    children,
    nofollow,
    eventData,
    disabled,
    ...rest
  },
  ref
) {
  const componentContext = useComponentContext();
  const { locale } = useTranslationContext();

  if (href && typeof href !== 'string') { throw Error(`Invalid href prop passed to SmartLink: ${href}")`); }

  if (disabled || !href) {
    return (
      <div className={inlineBlock_} {...rest}>
        {children}
      </div>
    );
  }

  let cleanHref = getPathFromLocalUrl(href);
  const isLegacySignupLink = cleanHref.includes(LEGACY_SIGNUP_URL);
  const isLoginLink = cleanHref.includes(LOCALIZED_LOGIN_URL);
  const isLinkToWebProduct = cleanHref.startsWith(WEB_PRODUCT_URL);

  if (isLegacySignupLink || isLoginLink) {
    const args = cleanHref.split('&').slice(1);
    cleanHref = isLegacySignupLink ? getLegacySignupUrl({ locale }) : getLoginUrl({ locale });
    if (args.length > 0) {
      cleanHref = `${cleanHref}&${args.join('&')}`;
    }
  }

  const LinkTag = getLinkTag({ href: cleanHref });
  const defaultTarget = isExternalLink(LinkTag) ? '_blank' : '_self';
  const target = isLegacySignupLink || isLoginLink || isLinkToWebProduct ? '_self' : defaultTarget;
  const rel = getRel(LinkTag, nofollow);

  const isLocalizedLink = LinkTag === LocalizedLink;
  const hrefProp = isLocalizedLink ? { to: cleanHref } : { href: cleanHref };

  const handleOnClick = e => {
    if (onClick) {
      // call if passed as prop
      onClick(e);
    }

    const thisHref =
      isLocalizedLink && !pathnameHasLocale(cleanHref)
        ? replaceLocaleInPathname(cleanHref, locale)
        : cleanHref;

    trackSmartLinkClick(
      {
        href: thisHref,
        label: children,
        ...eventData,
      },
      componentContext
    );

    // link points to the current page. this can make the link appear to be
    // dead/broken, because tapping it does nothing. force a refresh to give
    // the appearance of things happening.
    if (window.location.pathname === thisHref && !thisHref.includes('#')) {
      window.location.reload();
    }
  };

  return (
    <LinkTag
      {...hrefProp}
      target={target}
      rel={rel}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={ref}
      {...rest}
    >
      {children}
    </LinkTag>
  );
});

export default SmartLink;

SmartLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nofollow: PropTypes.bool,
  eventData: PropTypes.shape({}),
  disabled: PropTypes.bool,
};
