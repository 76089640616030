// Converts css aspect-ratio string("16/9") values to a normalized number
export const normalizeAspectRatioString = aspectRatioStr => {
  if (typeof aspectRatioStr === 'number') {
    return aspectRatioStr;
  }
  const ratioParts = aspectRatioStr.split('/');
  if (ratioParts.length === 1) {
    return Number(ratioParts[0]);
  }
  return Number(ratioParts[0]) / Number(ratioParts[1]);
};

// Used to for bumping up texture/canvas dimensions to the next highest
// power2, which gives a major performance boost - because computers.
export const roundUpPower2 = n => {
  // eslint-disable-next-line no-restricted-properties
  return Math.pow(2, Math.ceil(Math.log(n) / Math.log(2)));
};

// For when we have a range of 0-1, but want to offset the value to a narrower range.
// Exmample: offsetNormalizedValue(0.5, 0.2, 0.8) = 0.6
export const offsetNormalizedValue = (globalPercent, offsetStart, offsetEnd) => {
  const stepDiff = offsetEnd - offsetStart;
  const percentDiff = globalPercent - offsetStart;
  return percentDiff / stepDiff;
};

// returns a number with a max amount of decimal places
export const setDecimalPrecision = (num, decimalPlaces) => {
  // eslint-disable-next-line no-restricted-properties
  return Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
};

// Ensures that the "num" value never leaves the min-max range, which means
// clamp(0, 11, 10) will return 10 and clamp(0, -1, 10) will return 0.
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// converts 0.5 to "1/2"
export const convertDecimalToRatioString = decimal => {
  const greatestCommonDenominator = (a, b) => {
    if (!b) return a;
    return greatestCommonDenominator(b, a % b);
  };

  const len = decimal.toString().length - 2;
  let denominator = 10 ** len;
  let numerator = decimal * denominator;
  const divisor = greatestCommonDenominator(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;

  return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
};

const stringTo32BitHash = str => {
  let v = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    v += str.charCodeAt(i) << i % 24;
  }
  return v % 0xffffffff;
};

const createRandomGenerator = seed => {
  // some big numbers
  const a = 5486230734;
  const b = 6908969830;
  const m = 9853205067;
  let x = seed;
  // returns a random value 0 <= num < 1
  return (_seed = x) => {
    x = (_seed * a + b) % m;
    return x / m;
  };
};

// By using a seed(string) we can generate a static random order, meaning
// using any string will consistently randomly order an array the same way.
export const arrayShuffleSeededRandom = (str, arr) => {
  if (!arr?.length) return arr;

  const rArr = [];
  const copyArr = arr.slice(0);
  const random = createRandomGenerator(stringTo32BitHash(str));
  while (copyArr.length > 1) {
    rArr.push(copyArr.splice(Math.floor(random() * copyArr.length), 1)[0]);
  }
  rArr.push(copyArr[0]);
  return rArr;
};
