import isString from 'lodash/isString';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';

const getLabelFromChildren = children => {
  if (!children) {
    return '';
  }

  if (isString(children)) {
    return children;
  }
  return (children.length ? children : [children])
    .map(child => {
      if (isString(child)) {
        return child;
      }
      if (typeof child === 'object') {
        if (child.type === 'img') {
          return child?.props?.alt || child?.props?.src;
        }
        if (isString(child?.props?.children)) {
          return child.props.children;
        }
        return '';
      }
      return '';
    })
    .filter(item => isString(item))
    .join(' ')
    .trim();
};

const trackSmartLinkClick = ({ label, href, ...restData }, context) => {
  const mergedData = {
    href,
    // don't bother trying to extract label if one is manually provided
    label: restData.label ? null : getLabelFromChildren(label),
    name: EVENT_NAMES.LINK_CLICKED,
    ...restData,
  };

  const { name, ...eventProps } = mergedData;

  trackAnalyticsEvent(name, eventProps, context);
};

export { getLabelFromChildren, trackSmartLinkClick };
