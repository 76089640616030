'use client';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import remarkSubSuper from 'remark-sub-super';
import ReactMarkdown from 'react-markdown';
import {
  DEFAULT_RENDERERS,
  MARKDOWN_MODES,
  MODE_CONFIGS
} from './markdown.utils';

export const Markdown = ({
  source,
  renderers,
  className,
  escapeHtml,
  unwrapElements,
  mode,
  disallowedTypes = [],
  ...rest
}) => {
  const { renderers: currentModeRenderers, disallowedTypes: currentModeDisallowedTypes } = MODE_CONFIGS[mode] || {};

  const mergedRenderers = useMemo(() => {
    return {
      ...DEFAULT_RENDERERS,
      ...(currentModeRenderers ?? {}),
      ...renderers,
    };
  }, [renderers, currentModeRenderers]);

  // Valid types for `disallowedTypes`:
  // https://github.com/remarkjs/react-markdown/tree/v4.3.1#node-types
  const baseDisallowedTypes = unwrapElements?.length > 0 ? Array.of(unwrapElements).flat() : [];

  const finalDisallowedTypes = currentModeDisallowedTypes
    ? [...new Set([...baseDisallowedTypes, ...currentModeDisallowedTypes])]
    : [...new Set([...baseDisallowedTypes, ...disallowedTypes])];

  return (
    <ReactMarkdown
      escapeHtml={escapeHtml}
      className={className}
      source={source}
      plugins={[remarkSubSuper]}
      renderers={mergedRenderers}
      disallowedTypes={finalDisallowedTypes}
      unwrapDisallowed={!!unwrapElements}
      {...rest}
    />
  );
};

// TODO: figure out why in certain tests, `MARKDOWN_MODES` is null/undefined,
// and thus passing it into `Object.values()` causes a test failure. The module
// is not mocked anywhere as of this writing. :shrug:
const ALLOWED_MARKDOWN_MODES_AS_STRINGS = Object.values(MARKDOWN_MODES ?? {});

Markdown.propTypes = {
  source: PropTypes.string,
  renderers: PropTypes.shape({}),
  className: PropTypes.string,
  escapeHtml: PropTypes.bool,
  unwrapElements: PropTypes.oneOfType([
    PropTypes.oneOf(ReactMarkdown.types),
    PropTypes.arrayOf(PropTypes.oneOf(ReactMarkdown.types)),
  ]),
  mode: PropTypes.oneOf(ALLOWED_MARKDOWN_MODES_AS_STRINGS),
  disallowedTypes: PropTypes.arrayOf(PropTypes.oneOf(ReactMarkdown.types)),
};
